import StateService from "./StateService";
import ListenerService from "./ListenerService";

class GlobalService {
  stateService: StateService;

  listenerService: ListenerService;

  constructor() {
    this.stateService = new StateService();
    this.listenerService = new ListenerService();
  }
}
export default GlobalService;
