import React, { useEffect, useState } from "react";
import "./tableView.scss";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material";
import { useI18n } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { Spinner, ListTable, ICOLUMNSDATA, ELEMENT } from "dms-lib";
import { INCIDENTS_AUTOMATIC_UPDATE_TIMER } from "../../../utils/Constants";
import { AutomaticRefreshFeature } from "../../../models/dashboardAutomaticRefresh/AutomaticRefreshFeature";
import { convertSecToMili } from "../../../utils/fp";
import TableActionsHeader from "../../supervisorDashboard/dynamicResultTable/tableActionsHeader/TableActionsHeader";
import { ClearIncidentRequestDTO } from "../../../models/supervisorDashboard/ClearIncidentRequestDTO";
import { OperationIncidentInfo } from "../../../models/notification/OperationIncidentInfo";
import { OperationIncidentDTO } from "../../../models/supervisorDashboard/OperationIncidentDTO";
import IncidentActivationsPanel from "./incidentActivationsDetails/IncidentActivationsDetails";
import { usePagination } from "../../../hooks/UsePagination";
import { useGlobalContext } from "../../../contexts/GlobalContext";

interface TableViewProps {
  columns: ICOLUMNSDATA<OperationIncidentDTO>;
  data: ELEMENT<OperationIncidentDTO>[];
  showActivationsDetailsPanel?: boolean;
  hasData?: boolean;
  onRowClickCallback?: (event) => void;
  apiCallFunc?: ({ pageIndex, pageSize }, sortBy?, sortDirection?) => void;
  totalDataCount?: number;
  totalPageCount?: number;
  loading?: boolean;
  rowsPerPage?: number;
  selectedRow?: OperationIncidentDTO;
  showTotal?: boolean;
  totalText?: string;
  paginationPosition?: string; // enum if exists more overrided position
  resultTableCallback?: (result: ClearIncidentRequestDTO) => void; // if specific incident clicked, then pass its id,if not pass empty string
  badgeClickCallback?: () => void;
  operationIncidentInfoSubject?: BehaviorSubject<OperationIncidentInfo>;
  automaticRefreshFeature?: AutomaticRefreshFeature;
  onCloseSidePanel?: () => void;
}

const TableView = (props: TableViewProps): JSX.Element => {
  const {
    columns,
    data,
    showActivationsDetailsPanel,
    selectedRow,
    onRowClickCallback,
    hasData,
    apiCallFunc,
    totalDataCount,
    totalPageCount,
    loading,
    rowsPerPage,
    showTotal,
    totalText,
    paginationPosition,
    resultTableCallback,
    badgeClickCallback,
    operationIncidentInfoSubject,
    automaticRefreshFeature,
    onCloseSidePanel,
  } = props;

  const { pageChangeSubject } = useGlobalContext()?.stateService;
  const [refreshPage, setRefreshPage] = useState<number>();
  const { pageState, nextPage, previousPage, gotoPage } = usePagination(
    totalPageCount,
    rowsPerPage
  );

  const { t: translate } = useI18n();

  const isAutomaticRefreshActive =
    automaticRefreshFeature?.enabled && automaticRefreshFeature?.value;

  useEffect(() => {
    const sub = pageChangeSubject?.subscribe(gotoPage);
    return () => {
      sub.unsubscribe();
    };
  }, [pageChangeSubject]);

  useEffect(() => {
    if (apiCallFunc) {
      apiCallFunc({
        pageIndex: pageState.pageIndex,
        pageSize: pageState.pageSize,
      });
    }
  }, [apiCallFunc, pageState]);

  const pageActions = (event) => {
    if (event.currentTarget?.innerText) {
      gotoPage(Number(event.currentTarget.innerText) - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to next page") {
      nextPage();
    }

    if (event.currentTarget.ariaLabel === "Go to last page") {
      gotoPage(pageState.totalPages - 1);
    }

    if (event.currentTarget.ariaLabel === "Go to previous page") {
      previousPage();
    }

    if (event.currentTarget.ariaLabel === "Go to first page") {
      gotoPage(0);
    }
  };

  const TablePaginationActions = () => {
    const theme = useTheme();

    return (
      <>
        <div
          style={{
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
          }}
        >
          <Pagination
            count={Math.ceil(
              (totalDataCount || data.length) / pageState.pageSize
            )}
            onChange={pageActions}
            showFirstButton
            showLastButton
            page={pageState.pageIndex + 1}
            data-cr="table-view-pagination"
          />
        </div>
      </>
    );
  };

  const getPaginated = () => {
    return (
      <>
        <Table
          className={`dashboards__incident-pagination paginated-table ${
            paginationPosition || ""
          }`}
        >
          <TableBody>
            <TableRow className="table-view-footer">
              {showTotal && (
                <TableCell className="table-cell__total">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "space-between",
                      gap: "5px",
                    }}
                  >
                    <p
                      style={{
                        color: "var(--primary)",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                      data-cr="supervisor-incidents-count"
                    >
                      {totalDataCount} {totalText}
                      {isAutomaticRefreshActive &&
                        ` | ${translate(
                          "supervisor.incidentTable.incidentTableFooter.incidentsAutomaticUpdate",
                          {
                            count: convertSecToMili(
                              INCIDENTS_AUTOMATIC_UPDATE_TIMER
                            ),
                          }
                        )}`}
                    </p>
                  </div>
                </TableCell>
              )}
              <TablePagination
                rowsPerPageOptions={[]}
                count={totalDataCount || data.length}
                rowsPerPage={pageState.pageSize}
                page={pageState.pageIndex}
                onPageChange={() => {}}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                ActionsComponent={TablePaginationActions}
                className="table-cell__pagination"
              />
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner size="large" />
        </div>
      ) : (
        <>
          <div className="table-wrapper" data-cr="de-table-wrapper">
            <TableActionsHeader
              title={translate("supervisor.title")}
              hasTableData={hasData}
              operationIncidentInfoSubject={operationIncidentInfoSubject}
              resultTableCallback={resultTableCallback}
              onClickCallBack={badgeClickCallback}
              automaticRefreshFeature={automaticRefreshFeature}
              className="dashboards__incident-header"
            />
            <div className="table-list">
              <ListTable<OperationIncidentDTO>
                columns={columns}
                rowsData={data}
                currentSelectedElement={selectedRow}
                onRowClick={onRowClickCallback}
                className={showActivationsDetailsPanel ? "grid-collapse" : ""}
                isRowInteractable
                noDataLabel={translate("supervisor.incidentTable.noIncidents")}
              >
                {(showActivationsDetailsPanel && (
                  <IncidentActivationsPanel
                    selectedRowId={selectedRow?.id}
                    selectedSiteId={selectedRow.siteId}
                    selectedSiteName={selectedRow.siteName}
                    selectedRowNumberOfActivations={
                      selectedRow?.additionalActivationsCount
                    }
                    onClose={onCloseSidePanel}
                  />
                )) ||
                  null}
              </ListTable>
            </div>
          </div>
          {hasData && getPaginated()}
        </>
      )}
    </>
  );
};

TableView.defaultProps = {
  hasData: true,
  loading: false,
  rowsPerPage: 10,
  showActivationsDetailsPanel: false,
  showTotal: false,
  selectedRow: null,
  totalText: " records.",
  paginationPosition: "centered",
  onRowClickCallback: () => null,
  apiCallFunc: () => null,
  totalDataCount: null,
  totalPageCount: null,
  resultTableCallback: () => null,
  badgeClickCallback: () => null,
  operationIncidentInfoSubject: null,
  automaticRefreshFeature: undefined,
  onCloseSidePanel: undefined,
};

export default TableView;
