// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./incidentActivationsDetails.scss";
// Components
import { NoElementsFound } from "dms-lib";
import ActivationsDetailsHeader from "./activationsDetailsHeader/ActivationsDetailsHeader";
import ActivationsDetailsList from "./activationsDetailsList/ActivationsDetailsList";

interface IncidentActivationsPanelProps {
  selectedRowId: string;
  selectedSiteId: string;
  selectedSiteName: string;
  selectedRowNumberOfActivations: number;
  onClose: (event) => void;
}

const IncidentActivationsPanel = ({
  selectedRowId,
  selectedSiteId,
  selectedSiteName,
  selectedRowNumberOfActivations,
  onClose,
}: IncidentActivationsPanelProps): JSX.Element => {
  const { t: translate } = useI18n();

  /**
   * This is used for table columns and
   * its configuration and style.
   */
  const activationsHeaders = React.useMemo(
    () => ({
      Resource: `${translate("supervisor.incidentTable.resource")}:`,
      TriggeredTime: `${translate("supervisor.incidentTable.triggeredTime")}:`,
    }),
    []
  );

  return (
    <div
      className="incident-activations__details"
      style={
        {
          "--activation-columns-size": Object.keys(activationsHeaders).length,
        } as React.CSSProperties
      }
    >
      <ActivationsDetailsHeader onClose={onClose} />
      {selectedRowNumberOfActivations ? (
        <ActivationsDetailsList
          selectedRowId={selectedRowId}
          selectedSiteId={selectedSiteId}
          selectedSiteName={selectedSiteName}
          selectedRowNumberOfActivations={selectedRowNumberOfActivations}
          activationsHeaders={activationsHeaders}
        />
      ) : (
        <NoElementsFound
          className="incident-activations__not-found"
          message={translate("supervisor.activationDetails.noActivations")}
        />
      )}
    </div>
  );
};

export default IncidentActivationsPanel;
