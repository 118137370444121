import React from "react";
import { useI18n } from "compass-commons";
import { NoDataFound } from "dms-lib";

const NoDataComponent = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div className="ondemand-no-incidents">
      <NoDataFound
        has404Text={false}
        title={translate("ondemand.noIncidentDialog.title")}
        content={translate("ondemand.noIncidentDialog.reason")}
      />
    </div>
  );
};

export default NoDataComponent;
