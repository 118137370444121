import { HttpClient } from "compass-shared-services";
import { getUrl, normalizeAPIObject } from "compass-commons";
import { PagedOperationIncidentListDTO } from "../models/supervisorDashboard/PagedOperationIncidentListDTO";
import { ClearIncidentRequestDTO } from "../models/supervisorDashboard/ClearIncidentRequestDTO";
import { IncidentAdditionalActivationDTO } from "../models/supervisorDashboard/IncidentAdditionalActivation";
import { IncidentAdditionalActivationResponseDTO } from "../models/supervisorDashboard/IncidentAdditionalActivationResponseDTO";
import {
  OnDemandIncidentDTO,
  OnDemandIncidentResponseDTO,
} from "../models/onDemandIncident/onDemandIncident";
import { OnDemandIncidentFormSubmit } from "../models/onDemandIncident/onDemandIncidentForm";

const { OPERATION_INCIDENT_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_INCIDENT_LIST: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents`,
  CLEAR_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/execution/(executionId)/clear-unassigned`,
  CLEAR_ALL_INCIDENTS: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/clear-all`,
  ASSIGN_INCIDENT_TO_USER: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/assign`,
  // ACTIVATION
  GET_ADDITIONAL_ACTIVATIONS: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/additional-activations`,
  // ON DEMAND
  CREATE_ONDEMAND_INCIDENT: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents`,
};

interface ClearAllRequestDTO {
  description: string;
}

interface ClearRequestDTO {
  description: string;
}

/**
 * Operator Incident Manager Service where backend service is called for
 * * incident Lists
 */
export default class OIMService {
  // We need to check the pageIndex and only abort if it is the same
  // Otherwise it can be a fast scroller
  static abortControllerData: {
    pageIndex: number;
    controller: AbortController;
  };

  static async getIncidentList(
    pageNumber = 0,
    pageSize = 10
  ): Promise<PagedOperationIncidentListDTO> {
    const urlPath = `${URL_PATHS.GET_INCIDENT_LIST}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return httpClient
      .get<PagedOperationIncidentListDTO>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async clearIncident(
    clearIncidentRequest: ClearIncidentRequestDTO
  ): Promise<string> {
    const uri = {
      incidentId: clearIncidentRequest.incidentId,
      executionId: clearIncidentRequest.executionId,
    };
    const urlPath = getUrl(URL_PATHS.CLEAR_INCIDENT, uri);
    const body: ClearRequestDTO = {
      description: clearIncidentRequest.comment,
    };
    return httpClient
      .delete<string>({ url: urlPath, config: { data: body } })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async clearAllIncidents(
    clearIncidentRequest: ClearIncidentRequestDTO
  ): Promise<number> {
    const body: ClearAllRequestDTO = {
      description: clearIncidentRequest.comment,
    };

    return httpClient
      .postReturningAllResponse<any>({
        url: URL_PATHS.CLEAR_ALL_INCIDENTS,
        payload: body,
      })
      .then((response) => {
        return response.status;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async assignIncidentToUser(
    incidentId: string,
    userId: string
  ): Promise<string> {
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.ASSIGN_INCIDENT_TO_USER, uri);
    const body = {
      userId,
    };
    return httpClient
      .post<any>({ url: urlPath, payload: body })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(JSON.stringify(error));
      });
  }

  // ADDITIONAL ACTIVATIONS

  static async getAdditionalActivations(
    incidentId: string,
    pageParameters?: {
      pageIndex: number;
      pageSize: number;
    }
  ): Promise<IncidentAdditionalActivationDTO[]> {
    if (this.abortControllerData?.pageIndex === pageParameters?.pageIndex)
      this.abortControllerData.controller.abort();
    this.abortControllerData = {
      pageIndex: pageParameters?.pageIndex || 0,
      controller: new AbortController(),
    };
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.GET_ADDITIONAL_ACTIVATIONS, uri);
    return httpClient
      .get<IncidentAdditionalActivationResponseDTO>({
        url: urlPath,
        config: {
          params: {
            pageIndex: pageParameters?.pageIndex,
            pageSize: pageParameters?.pageSize,
          },
          signal: this.abortControllerData.controller.signal,
        },
      })
      .then((response) => {
        return normalizeAPIObject(response.additionalActivations);
      })
      .catch((error) => {
        throw Error(error);
      });
  }

  static async createOnDemandIncident(
    formData: OnDemandIncidentFormSubmit
  ): Promise<OnDemandIncidentDTO> {
    return httpClient
      .post<OnDemandIncidentResponseDTO>({
        url: URL_PATHS.CREATE_ONDEMAND_INCIDENT,
        payload: JSON.stringify(formData),
      })
      .then((response) => {
        return normalizeAPIObject(response.onDemandIncident);
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}
