// General
import { HttpClient } from "compass-shared-services";
import {
  SiteTreeDto,
  SiteTreeResponseDto,
} from "../models/onDemandIncident/sitesTree";

const { SITE_MANAGER_PATH } = appConfig;
const URL_PATHS = {
  SM_ALL_SITES: `${SITE_MANAGER_PATH}/site-configuration/site-tree`,
};

const httpClient = new HttpClient(appConfig);

const convertSitesTreeToArray = (
  sitesTree: SiteTreeResponseDto,
  result: SiteTreeDto[] = []
) => {
  if (!sitesTree) return result;
  const { childSites, ...siteProps } = sitesTree;
  result.push(siteProps);
  if (childSites && childSites.length > 0) {
    for (let i = 0; i < sitesTree.childSites.length; i++) {
      convertSitesTreeToArray(childSites[i], result);
    }
  }
  return result;
};

export default class SiteService {
  static async getAllSites(): Promise<SiteTreeDto[]> {
    return httpClient
      .get<SiteTreeResponseDto>({ url: URL_PATHS.SM_ALL_SITES })
      .then((response) => {
        return convertSitesTreeToArray(response);
      })
      .catch((error) => {
        throw Error(error?.data?.message || error);
      });
  }
}
