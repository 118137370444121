// General
import React from "react";
// Styles
import "./activationsDetailsListHeader.scss";

interface ActivationsDetailsListHeaderProps {
  activationsHeaders: {
    Resource: string;
    TriggeredTime: string;
  };
}

const ActivationsDetailsListHeader = ({
  activationsHeaders,
}: ActivationsDetailsListHeaderProps): JSX.Element => {
  const activationsHeadersKeys = Object.keys(activationsHeaders);

  return (
    <div className="incident-activations__list-header">
      {activationsHeadersKeys.map((actHeaders: string) => (
        <div
          key={`activations-col-header-${actHeaders}`}
          className="incident-activations__list-col"
        >
          {activationsHeaders[actHeaders]}
        </div>
      ))}
    </div>
  );
};

export default ActivationsDetailsListHeader;
