// General
import React from "react";
import { useI18n } from "compass-commons";
// Material UI
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
// Styles
import "../ODBehaviorDialogActions/ODBehaviorDialogActions.module.css";

const ODBehaviorDialogContent = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div className="od-behavior-dialog-content">
      <ReportProblemRoundedIcon
        className="og-dialog-icon-large"
        color="error"
      />
      <div className="od-behavior-dialog-wrapper">
        <span className="od-behavior-dialog-content-header">
          {translate("ondemand.noIncidentDialog.title")}
        </span>
        <span>{translate("ondemand.noIncidentDialog.reason")}</span>
      </div>
    </div>
  );
};

export default ODBehaviorDialogContent;
