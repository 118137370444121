import React, { useState } from "react";
import { Button, Spinner } from "dms-lib";
import { LocalizationNS, useI18n } from "compass-commons";
import "./formReAssignDialog.scss";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import PersonOffRoundedIcon from "@mui/icons-material/PersonOffRounded";
import CompassDialog from "../../commons/dialogCompass/DialogCompass";
import { IncidentAssignFormDTO } from "../../../models/onDemandIncident/incidentAssignForm";
import OperatorAssign from "../operatorAssign/OperatorAssign";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import OIMService from "../../../services/OIMService";
import { UserDTO } from "../../../models/users/UserDTO";
import { openOperationTabIfClosed } from "../../../utils/TabOpenUtils";

interface FormReAssignDialogProps {
  isOpen: boolean;
  incidentId: string;
  prevAssignedUser: string;
  onCancel: () => void;
  currentUser: UserDTO;
}

const FormReAssignDialog = ({
  isOpen,
  incidentId,
  prevAssignedUser,
  onCancel,
  currentUser,
}: FormReAssignDialogProps): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    stateService: { alertSubject },
  } = useGlobalContext();
  const [assignedUser, setAssignedUser] = useState(prevAssignedUser);
  const [isFirstAttempt, setIsFirstAttempt] = useState(true);
  const {
    control,
    watch,
    resetField,
    handleSubmit,
    setValue: setFieldValue,
    formState: { isSubmitting },
  } = useForm<IncidentAssignFormDTO>({
    mode: "all",
    resolver: zodResolver(IncidentAssignFormDTO),
    defaultValues: {
      incidentId,
      assign: {
        id: null,
        email: null,
        name: translate("ondemand.operatorAssignAutomaticOption"),
      },
    },
  });

  // eslint-disable-next-line consistent-return
  const onConfirm = async (formData: IncidentAssignFormDTO) => {
    try {
      // Automatic option should not change the assignee and navigate back
      if (!formData?.assign?.id) return onCancel();

      await OIMService.assignIncidentToUser(incidentId, formData.assign.id);
      alertSubject.next({
        title: translate("ondemand.reAssignForm.successMessage"),
        severity: "success",
      });

      // If assigned to me and tab is not open, we open it
      if (currentUser && currentUser?.userId === formData?.assign?.id) {
        openOperationTabIfClosed();
      }

      return onCancel();
    } catch {
      setIsFirstAttempt(false);
      setAssignedUser(formData.assign.name);
      resetField("assign");
    }
  };

  return (
    <>
      {isOpen && (
        <CompassDialog
          dataCr="ondemand-reassign-dialog"
          onClose={() => {}}
          dialogState={isOpen}
          dialogContent={
            <div className="ondemand-reassign-dialog-content">
              <div className="ondemand-reassign-dialog-content-title">
                <PersonOffRoundedIcon />
                <span data-cr="ondemand-reassign-title">
                  {translate("ondemand.reAssignForm.userOffline", {
                    user: assignedUser,
                  })}
                </span>
              </div>
              <OperatorAssign
                control={control}
                setFieldValue={setFieldValue}
                watch={watch}
                inputLabel={translate(
                  isFirstAttempt
                    ? "ondemand.reAssignForm.inputLabelDetailed"
                    : "ondemand.reAssignForm.inputLabel"
                )}
                currentUser={currentUser}
              />
            </div>
          }
          dialogActions={
            <div className="ondemand-reassign-dialog-actions">
              <Button
                dataCr="ondemand-reassign-confirm"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onConfirm)}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner size="small" />
                ) : (
                  translate("confirm", { ns: LocalizationNS.SHARED })
                )}
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default FormReAssignDialog;
