import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./onDemandIncident.scss";
import OnDemandContainer from "./onDemandContainer/OnDemandContainer";
import BehaviorService from "../../services/BehaviorService";
import NoDataComponent from "./noDataComponent/NoDataComponent";

const OnDemandIncident = (): JSX.Element => {
  const location = useLocation();
  const { clickedNewOnDemand } = location.state || {};
  const [hasBehavior, setHasBehavior] = useState<boolean | null>(true);
  useEffect(() => {
    if (!clickedNewOnDemand) {
      const getHasBehavior = async () => {
        try {
          const result = await BehaviorService.getCustomerHasOnDemandRules();
          setHasBehavior(result);
        } catch (error) {
          console.error("Error when trying to get behaviors", error);
          setHasBehavior(false);
        }
      };
      getHasBehavior();
    }
  }, [clickedNewOnDemand]);

  return hasBehavior ? <OnDemandContainer /> : <NoDataComponent />;
};

export default OnDemandIncident;
