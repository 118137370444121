// General
import React from "react";
import { useI18n } from "compass-commons";
import { Button } from "dms-lib";
// Styles
import "./ODBehaviorDialogActions.module.css";

interface ODBehaviorDialogActionsProps {
  onConfirm: (event?: any) => void;
}

const ODBehaviorDialogActions = ({
  onConfirm,
}: ODBehaviorDialogActionsProps): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <div className="og-dialog-actions__wrapper">
      <Button
        dataCr="confirm-od-behavior"
        color="primary"
        variant="contained"
        onClick={onConfirm}
      >
        {translate("ondemand.noIncidentDialog.okButton")}
      </Button>
    </div>
  );
};

export default ODBehaviorDialogActions;
