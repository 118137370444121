import React, { useEffect } from "react";
import { Control, Controller } from "react-hook-form";
import { AutoComplete } from "dms-lib";
import { useApi, useI18n } from "compass-commons";
import { SelectOption } from "../../../models/onDemandIncident/selectOption";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import BehaviorService from "../../../services/BehaviorService";

interface BehaviorSelectorProps {
  control: Control<OnDemandIncidentFormDTO>;
  selectedSite: string;
}

const BehaviorSelector = ({
  control,
  selectedSite,
}: BehaviorSelectorProps): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    stateService: { alertSubject },
  } = useGlobalContext();

  const { data, loading, error } = useApi(
    BehaviorService.getOnDemandRulesBySiteId,
    selectedSite,
    true
  );

  useEffect(() => {
    if (error) {
      alertSubject.next({
        title: translate("ondemand.failToLoadBehavior"),
      });
    }
  }, [error]);

  return (
    <Controller
      name="behavior"
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <div>
            <div className="ondemand-form-section-label">
              {translate("ondemand.behaviorInputLabel")}
            </div>
            <AutoComplete<SelectOption>
              {...field}
              disabled={!selectedSite}
              value={value as SelectOption}
              options={data || []}
              loading={loading}
              placeholder={translate("ondemand.behaviorInputPlaceholder")}
              dataCr="incident-selector-behavior"
              getOptionLabel={(option) => option?.name || ""}
              onChangeCallback={(behavior: SelectOption) => {
                onChange(behavior);
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default BehaviorSelector;
