import { BehaviorSubject } from "rxjs";
import { IAlertNotification } from "dms-lib";
import { ClearIncidentRequestDTO } from "../models/supervisorDashboard/ClearIncidentRequestDTO";
import { OperationIncidentInfo } from "../models/notification/OperationIncidentInfo";
import { DashboardActivationsWrapper } from "../models/supervisorDashboard/IncidentAdditionalActivation";
import { PagedOperationIncidentAsMap } from "../models/supervisorDashboard/PagedOperationIncidentListDTO";

export const PAGE_OPERATION_INCIDENT_INITIAL_STATE: PagedOperationIncidentAsMap =
  {
    operationIncidentMap: {},
    totalPages: 0,
    totalRecords: 0,
  };

class StateService {
  public clearIncidentHolder: BehaviorSubject<ClearIncidentRequestDTO>;

  public latestTriggeredTimeByResult: BehaviorSubject<number>;

  public operationIncidentInfo: BehaviorSubject<OperationIncidentInfo>;

  public alertSubject: BehaviorSubject<IAlertNotification>;

  public incidentActivationsDetails: BehaviorSubject<{
    [key: string]: DashboardActivationsWrapper;
  }>;

  public pageChangeSubject: BehaviorSubject<number>;

  public operationIncidentMap: BehaviorSubject<PagedOperationIncidentAsMap>;

  constructor() {
    this.clearIncidentHolder = new BehaviorSubject<ClearIncidentRequestDTO>(
      null
    );
    this.operationIncidentInfo = new BehaviorSubject<OperationIncidentInfo>(
      null
    );
    this.latestTriggeredTimeByResult = new BehaviorSubject<number>(null);
    this.alertSubject = new BehaviorSubject<IAlertNotification>(null);
    this.incidentActivationsDetails = new BehaviorSubject<{
      [key: string]: DashboardActivationsWrapper;
    }>({});
    this.pageChangeSubject = new BehaviorSubject<number>(0);
    this.operationIncidentMap =
      new BehaviorSubject<PagedOperationIncidentAsMap>(
        PAGE_OPERATION_INCIDENT_INITIAL_STATE
      );
  }
}

export default StateService;
