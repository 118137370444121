import { ResourceMapping } from "../resource/ResourceMapping";

export enum EActivationsState {
  FIRST_PAGE = "firstPage",
  SOCKET_ACTIVATION = "socketActivation",
  DEFAULT = "default",
}
export interface IncidentAdditionalActivationDTO {
  id: string;
  activationTimestamp: string;
  resourceMappingId: string;
}

export interface OGAdditionalActivations {
  additionalActivationsCount: number;
  additionalActivations: IncidentAdditionalActivationDTO[];
  mapRMIdToRM: { [key: string]: ResourceMapping };
  isAdditionalActivationVisible: boolean;
}

export interface DashboardActivations {
  id: string;
  siteName: string;
  resourceMappingName: string;
  triggeredTime: string;
}

export interface DashboardActivationsWrapper {
  // Variable that controls if skeletons should be shown or not
  // 0 means no loading occuring
  // Other number means the number of skeletons we should show
  loading: number;
  activations: DashboardActivations[];
  totalNumber: number;
  error?: string;
}
