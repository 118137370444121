import React, { useEffect, useState } from "react";
import {
  Control,
  Controller,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";
import { AutoComplete } from "dms-lib";
import { useApi, useI18n } from "compass-commons";
import SiteService from "../../../services/SiteService";
import { SelectOption } from "../../../models/onDemandIncident/selectOption";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import { useGlobalContext } from "../../../contexts/GlobalContext";

interface SiteSelectorProps {
  control: Control<OnDemandIncidentFormDTO>;
  setFieldValue: UseFormSetValue<OnDemandIncidentFormDTO>;
  resetField: UseFormResetField<OnDemandIncidentFormDTO>;
}

const SiteSelector = ({
  control,
  setFieldValue,
  resetField,
}: SiteSelectorProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { data, loading, error } = useApi(SiteService.getAllSites);
  const {
    stateService: { alertSubject },
  } = useGlobalContext();
  const [isDisabled, setIsDisabled] = useState(false);

  // Auto select site when only one is available
  useEffect(() => {
    if (data?.length === 1) {
      setFieldValue(
        "site",
        { id: data[0].id, name: data[0].name },
        { shouldDirty: true }
      );
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      alertSubject.next({
        title: translate("ondemand.failToLoadSites"),
      });
    }
  }, [error]);

  return (
    <Controller
      name="site"
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <div>
            <div className="ondemand-form-section-label">
              {translate("ondemand.siteInputLabel")}
            </div>
            <AutoComplete<SelectOption>
              {...field}
              value={value as SelectOption}
              options={data || []}
              loading={loading}
              disabled={isDisabled}
              placeholder={translate("ondemand.siteInputPlaceholder")}
              dataCr="incident-selector-site"
              getOptionLabel={(option) => option?.name || ""}
              onChangeCallback={(site: SelectOption) => {
                onChange(site);
                resetField("floorplan", { defaultValue: null });
                resetField("behavior", { defaultValue: null });
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default SiteSelector;
