// General
import { HttpClient } from "compass-shared-services";
import { BehaviourLightDto } from "../models/onDemandIncident/behaviourDTO";
import { ODBehaviorRuleDTO } from "../models/onDemandIncident/oDBehaviorRuleDTO";
import { SelectOption } from "../models/onDemandIncident/selectOption";

const { INCIDENT_RULES_ENGINE_PATH } = appConfig;
const URL_PATHS = {
  RULES_ENGINE: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules`,
  ON_DEMAND: `${INCIDENT_RULES_ENGINE_PATH}/incident-rules/on-demand`,
};

const httpClient = new HttpClient(appConfig);

export default class BehaviorService {
  static async getOnDemandRulesBySiteId(
    siteId: string
  ): Promise<SelectOption[]> {
    const urlPath = `${URL_PATHS.RULES_ENGINE}`;
    return httpClient
      .get<BehaviourLightDto[]>({
        url: urlPath,
        config: {
          params: {
            siteId,
            availableForOnDemand: true,
            triggeredDisabled: false,
            filterByWeekDays: true,
          },
        },
      })
      .then((response) =>
        response.map(({ ruleId, displayName }) => {
          return { id: ruleId, name: displayName };
        })
      )
      .catch((error) => {
        throw Error(error);
      });
  }

  static async getCustomerHasOnDemandRules(): Promise<boolean> {
    const urlPath = `${URL_PATHS.ON_DEMAND}`;
    return httpClient
      .get<ODBehaviorRuleDTO>({
        url: urlPath,
        config: {
          params: {
            filterByWeekDays: true,
          },
        },
      })
      .then(({ hasBehavior }) => hasBehavior)
      .catch((error) => {
        throw Error(error);
      });
  }
}
