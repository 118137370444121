// General
import { HttpClient } from "compass-shared-services";
import { CommonIdentifier, getUrl } from "compass-commons";

const { FLOOR_PLAN_MANAGER_PATH } = appConfig;
const URL_PATHS = {
  GET_SITE_FLOORPLAN: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans`,
};

const httpClient = new HttpClient(appConfig);

export default class FloorplanService {
  static async getFloorPlansBySiteId(
    siteId: string | number
  ): Promise<CommonIdentifier[]> {
    const urlPath = getUrl(URL_PATHS.GET_SITE_FLOORPLAN, { siteId });
    return httpClient
      .get<CommonIdentifier[]>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
