import { string, object, z } from "zod";

export const IncidentAssignFormDTO = object({
  incidentId: string().min(1),
  assign: object({
    id: string().nullable(),
    name: string(),
    email: string().nullable(),
  })
    .optional()
    .nullable(),
});

export type IncidentAssignFormDTO = z.infer<typeof IncidentAssignFormDTO>;

export interface IncidentAssignSubmit {
  incidentId: string;
  assignToUserId: string | null;
}
