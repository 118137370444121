import { BehaviorSubject } from "rxjs";
import { useI18n } from "compass-commons";
import { useGlobalContext } from "../contexts/GlobalContext";
// Services
import OIMService from "../services/OIMService";
import ResourceMappingService from "../services/ResourceMappingService";
// Models
import {
  EActivationsState,
  DashboardActivations,
  DashboardActivationsWrapper,
  // DashboardDynamicFieldsMap,
  IncidentAdditionalActivationDTO,
} from "../models/supervisorDashboard/IncidentAdditionalActivation";
import { ResourceMappingLite } from "../models/resource/ResourceMapping";
import { useDashboardDynamicFields } from "./useDashboardDynamicFields";
import { PagedOperationIncidentAsMap } from "../models/supervisorDashboard/PagedOperationIncidentListDTO";

export const DEFAULT_PAGE_SIZE = 20;

const useActivations = (params?: {
  pagesize?: number;
  incidentActivationsParam?: BehaviorSubject<{
    [key: string]: DashboardActivationsWrapper;
  }>;
  operationIncidentMapParams?: BehaviorSubject<PagedOperationIncidentAsMap>;
}): {
  getActivationsDetails: (
    id: string,
    siteId: string,
    siteName: string,
    totalNumber: number,
    activationsState?: EActivationsState
  ) => void;
} => {
  const { pagesize, incidentActivationsParam, operationIncidentMapParams } =
    params || {};
  const { t } = useI18n();
  const { stateService } = useGlobalContext();
  const { updateOperationIncidentItem } = useDashboardDynamicFields(
    operationIncidentMapParams
  );
  const { incidentActivationsDetails } = stateService || {
    incidentAdditionalActivations: incidentActivationsParam,
    operationIncidentMap: operationIncidentMapParams,
  };

  const loadingSkeletonsSize = (
    pageIndex: number,
    totalNumber: number,
    currentActivationsLength?: number
  ) => {
    const currentLoadedRecords = currentActivationsLength || 0;
    if (currentLoadedRecords >= (pageIndex + 1) * DEFAULT_PAGE_SIZE) return 0;

    return Math.min(
      totalNumber - (currentActivationsLength || 0),
      DEFAULT_PAGE_SIZE
    );
  };

  const updateIncidentDetailsState = (
    id: string,
    pageIndex: number,
    activationsNewList: DashboardActivations[],
    totalNumber: number,
    overrideLoading?: number,
    error?: string
  ) => {
    incidentActivationsDetails.next({
      ...incidentActivationsDetails.value,
      [id]: {
        error,
        activations: activationsNewList,
        totalNumber,
        loading:
          overrideLoading ??
          loadingSkeletonsSize(
            pageIndex,
            totalNumber,
            activationsNewList.length
          ),
      },
    });
  };

  const fetchActivationsAndGenerateResourcesUniqueIDs = async (
    id,
    activationPageIndex,
    activationPageSize
  ): Promise<{
    pageActivations: IncidentAdditionalActivationDTO[];
    resourcesIdsUnique: Set<string>;
  }> => {
    const pageActivations = await OIMService.getAdditionalActivations(id, {
      pageIndex: activationPageIndex,
      pageSize: activationPageSize,
    });

    const resourcesIdsUnique = new Set(
      pageActivations.map((activation) => activation.resourceMappingId)
    );

    return { pageActivations, resourcesIdsUnique };
  };

  const fetchAndGenerateResourcesMap = async (
    siteId: string,
    resourceIds: string[]
  ): Promise<Map<string, ResourceMappingLite>> => {
    const resources = await ResourceMappingService.getResourcesMappingsLite(
      siteId,
      resourceIds
    );

    return resources.reduce((acc, resource) => {
      if (!acc.has(resource.id)) {
        acc.set(resource.id, resource);
      }
      return acc;
    }, new Map<string, ResourceMappingLite>());
  };

  const converToActivationDetails = (
    pageActivations: IncidentAdditionalActivationDTO[],
    resourcesMap: Map<string, ResourceMappingLite>,
    siteName
  ) =>
    pageActivations.map((activation: IncidentAdditionalActivationDTO) => {
      const currentResource = resourcesMap.get(activation.resourceMappingId);
      return {
        id: activation.id,
        siteName,
        resourceMappingName: currentResource?.name,
        triggeredTime: activation.activationTimestamp,
      };
    });

  const calculateActivations = (
    previousActivationList: DashboardActivations[],
    nextActivationList: DashboardActivations[]
  ): DashboardActivations[] => {
    const lastPageLeftOvers = previousActivationList.length % DEFAULT_PAGE_SIZE;

    return [
      ...previousActivationList,
      ...nextActivationList.slice(lastPageLeftOvers),
    ];
  };
  /**
   * Fetch resources by activations
   */
  const getActivationsAndResources = async (
    id: string,
    siteId: string,
    siteName: string,
    totalNumber: number,
    activationsState: EActivationsState
  ): Promise<void> => {
    // Only first page is cached, so if we changing rows, we already have the first page cached.
    const totalAdditionalActivations =
      activationsState === EActivationsState.FIRST_PAGE &&
      incidentActivationsDetails.value?.[id]?.activations.length
        ? incidentActivationsDetails.value?.[id].activations?.slice(
            0,
            DEFAULT_PAGE_SIZE
          )
        : [...(incidentActivationsDetails.value?.[id]?.activations || [])];

    const calculatePageIndex = Math.floor(
      totalAdditionalActivations.length / DEFAULT_PAGE_SIZE
    );

    const lastPageIndex = Math.floor(totalNumber / DEFAULT_PAGE_SIZE);

    const isLastPage = calculatePageIndex === lastPageIndex;

    // Page is changing if the difference between them are 1,
    // and the current activations reach the current page limit
    const isPageChanging =
      !((totalAdditionalActivations.length + 1) % DEFAULT_PAGE_SIZE) &&
      lastPageIndex - calculatePageIndex === 1;
    // If it is a message arriving from the socket we only fetch elements
    // if we are on the same page
    // or changing to the last page
    if (
      activationsState === EActivationsState.SOCKET_ACTIVATION &&
      !(isLastPage || isPageChanging)
    )
      return;

    try {
      updateIncidentDetailsState(
        id,
        calculatePageIndex,
        totalAdditionalActivations,
        totalNumber
      );

      const { pageActivations, resourcesIdsUnique } =
        await fetchActivationsAndGenerateResourcesUniqueIDs(
          id,
          calculatePageIndex,
          pagesize || DEFAULT_PAGE_SIZE
        );

      const resourceMap = await fetchAndGenerateResourcesMap(siteId, [
        ...resourcesIdsUnique,
      ]);

      const nextActivationsList = converToActivationDetails(
        pageActivations,
        resourceMap,
        siteName
      );

      const nextActivations = calculateActivations(
        totalAdditionalActivations,
        nextActivationsList
      );

      updateIncidentDetailsState(
        id,
        calculatePageIndex,
        nextActivations,
        totalNumber,
        0
      );
    } catch (error) {
      updateIncidentDetailsState(
        id,
        0,
        totalAdditionalActivations,
        totalNumber,
        0,
        t("supervisor.activationDetails.noActivations")
      );
    }
  };

  const getActivationsDetails = (
    id: string,
    siteId: string,
    siteName: string,
    totalNumber: number,
    activationsState = EActivationsState.DEFAULT
  ) => {
    getActivationsAndResources(
      id,
      siteId,
      siteName,
      totalNumber,
      activationsState
    );

    updateOperationIncidentItem(id, {
      additionalActivationsCount: totalNumber,
    });
  };

  return {
    getActivationsDetails,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useActivations };
