// General
import React, { useEffect, useMemo, useState } from "react";
import { safelyFormatDate } from "compass-commons";
// Styles
import "./activationsDetailsList.scss";
// Context
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
// Models
import {
  DashboardActivations,
  DashboardActivationsWrapper,
} from "../../../../../models/supervisorDashboard/IncidentAdditionalActivation";
import ActivationsDetailsListHeader from "./activationDetailsListHeader/ActivationsDetailsListHeader";
import ActivationsDetailsListData from "./activationDetailsListData/ActivationsDetailsListData";

interface ActivationsDetailsListProps {
  selectedRowId: string;
  selectedSiteId: string;
  selectedSiteName: string;
  selectedRowNumberOfActivations: number;
  activationsHeaders: {
    Resource: string;
    TriggeredTime: string;
  };
}

const ActivationsDetailsList = ({
  selectedRowId,
  selectedSiteId,
  selectedSiteName,
  selectedRowNumberOfActivations,
  activationsHeaders,
}: ActivationsDetailsListProps): JSX.Element => {
  const { stateService } = useGlobalContext();
  const { incidentActivationsDetails } = stateService;

  const [activationsMap, setActivationsMap] = useState<{
    [key: string]: DashboardActivationsWrapper;
  }>(incidentActivationsDetails.value);

  const { activations, loading, totalNumber, error } = useMemo(
    () =>
      activationsMap[selectedRowId] || {
        loading: 0,
        activations: [],
        totalNumber: 0,
      },
    [
      activationsMap[selectedRowId],
      selectedRowId,
      selectedRowNumberOfActivations,
    ]
  );

  const generateActivationsColumnsData = useMemo(
    () => ({
      resource: {
        fieldName: "resource",
        size: 40,
        headerName: activationsHeaders.Resource,
        accessor: (element: DashboardActivations) =>
          element.resourceMappingName,
      },
      triggeredTime: {
        fieldName: "triggeredTime",
        size: 30,
        headerName: activationsHeaders.TriggeredTime,
        accessor: (element: DashboardActivations) =>
          (element.triggeredTime && safelyFormatDate(element.triggeredTime)) ||
          "",
      },
    }),
    []
  );

  useEffect(() => {
    const sub = incidentActivationsDetails.subscribe((value) => {
      setActivationsMap(value);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [incidentActivationsDetails]);

  return (
    <>
      <ActivationsDetailsListHeader activationsHeaders={activationsHeaders} />
      <ActivationsDetailsListData
        selectedData={{
          selectedRowId,
          selectedSiteId,
          selectedSiteName,
          selectedRowNumberOfActivations,
        }}
        activations={activations}
        columnsFootprint={generateActivationsColumnsData}
        loading={loading}
        error={error}
        totalNumber={totalNumber}
      />
    </>
  );
};

export default ActivationsDetailsList;
