import { HttpClient } from "compass-shared-services";
import {
  ResourceMapping,
  ResourceMappingLite,
} from "../models/resource/ResourceMapping";

const { RESOURCE_MAPPING_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  RESOURCE_DETAIL: `${RESOURCE_MAPPING_MANAGER_PATH}/resource-mapping`,
};

export default class ResourceMappingService {
  private static readonly RESOURCE_MAPPING_ID = "resourceMappingId";

  private static readonly SITE_ID = "siteId";

  static async getResourcesMappings(
    siteId: string,
    resourceMappingsIds: string[]
  ): Promise<ResourceMapping[]> {
    const urlPath = URL_PATHS.RESOURCE_DETAIL;
    const params = new URLSearchParams();
    resourceMappingsIds.forEach((id) =>
      params.append(this.RESOURCE_MAPPING_ID, id)
    );
    params.append(this.SITE_ID, siteId);
    const config = {
      params,
    };
    return httpClient
      .get<ResourceMapping[]>({
        url: urlPath,
        config,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getResourcesMappingsLite(
    siteId: string,
    resourceMappingsIds: string[]
  ): Promise<ResourceMappingLite[]> {
    return this.getResourcesMappings(siteId, resourceMappingsIds).then((rmms) =>
      rmms.map(({ id, name }) => ({ id, name }))
    );
  }
}
