// General
import React, { useState, useEffect, useMemo, useRef } from "react";
// Styles
import { ICOLUMNS } from "dms-lib";
import "./activationsDetailsListData.scss";
// Components
import IncidentActivationDetailsSkeleton from "../../IncidentActivationDetailsSkeleton";
// Models
import { DashboardActivations } from "../../../../../../models/supervisorDashboard/IncidentAdditionalActivation";
import { useActivations } from "../../../../../../hooks/useActivations";

interface ActivationsDetailsListDataProps {
  selectedData: {
    selectedRowId: string;
    selectedSiteId: string;
    selectedSiteName: string;
    selectedRowNumberOfActivations: number;
  };
  activations: DashboardActivations[];
  columnsFootprint: {
    resource: ICOLUMNS<DashboardActivations>;
    triggeredTime: ICOLUMNS<DashboardActivations>;
  };
  loading: number;
  error: string;
  totalNumber: number;
}

const ActivationsDetailsListData = ({
  selectedData,
  activations,
  columnsFootprint,
  loading,
  error,
  totalNumber,
}: ActivationsDetailsListDataProps): JSX.Element => {
  // const UPDATE_THRESHOLD = "100px";
  const SCROLL_THRESHOLD = 100; // Threshold in pixels when to start infinity scroll

  // let lastListItemObserver: IntersectionObserver;
  const smoothScrollRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { getActivationsDetails } = useActivations();

  const [isScrolledUp, setIsScrolledUp] = useState(true);

  const handleLastListItem = () => {
    getActivationsDetails(
      selectedData.selectedRowId,
      selectedData.selectedSiteId,
      selectedData.selectedSiteName,
      selectedData.selectedRowNumberOfActivations
    );
  };

  // Controls if user already reached the end of the activations
  // Both validataions are important
  // First checks whether activations are lower than the total number
  // Second if an error ocurred and we already request a page higher than the existing total number
  const hasMoreActivations = useMemo(
    () => (activations?.length || 0) < totalNumber,
    [activations?.length, totalNumber]
  );

  const handleScroll = () => {
    const currentPos = listRef.current?.scrollTop;
    const { clientHeight, scrollHeight } = listRef.current || {};

    const x = currentPos + clientHeight;

    if (scrollHeight - x <= SCROLL_THRESHOLD) {
      if (hasMoreActivations && !loading && !error) handleLastListItem();
      setIsScrolledUp(false);
    } else {
      setIsScrolledUp(true);
    }
  };

  useEffect(() => {
    // If there's still room and there's items to fetch than we keep fetching.
    // We have to do it befor isScrolledUp because there's no scroll when this conditions happens
    const { clientHeight, scrollHeight } = listRef.current || {};
    if (
      scrollHeight <= clientHeight &&
      hasMoreActivations &&
      !loading &&
      !error
    )
      handleLastListItem();

    // 👇️ scroll to bottom every time messages change
    if (isScrolledUp) return;

    // Only scroll if user reach the end of the list and new activations are arriving
    if (!hasMoreActivations)
      smoothScrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [listRef, activations, isScrolledUp, loading, error]);

  return (
    <div
      className="incident-activations__list"
      onScroll={handleScroll}
      ref={listRef}
    >
      {activations?.map((actDetails) => (
        <div
          key={`activations-list-${actDetails.id}`}
          className="incident-activations__list-row"
        >
          {Object.keys(columnsFootprint).map((actKey) => {
            return (
              <div
                key={`activations-list-${actKey}-${actDetails.id}`}
                className="incident-activations__list-col"
              >
                {columnsFootprint[actKey].accessor(actDetails)}
              </div>
            );
          })}
        </div>
      ))}
      {loading ? (
        <IncidentActivationDetailsSkeleton
          numberOfRowSkeletons={loading}
          numberOfColumns={Object.keys(columnsFootprint).length}
        />
      ) : null}
      <div ref={smoothScrollRef} />
    </div>
  );
};

export default ActivationsDetailsListData;
