import React, { useMemo, useState } from "react";
import { useI18n } from "compass-commons";
// Material UI
import MenuIcon from "@mui/icons-material/Menu";
import { Menu, MenuItem } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
// Project modules
import "./incidentMenu.module.css";
import { Spinner } from "dms-lib";
import { OperationIncidentDTO } from "../../../models/supervisorDashboard/OperationIncidentDTO";
import UserManagerService from "../../../services/UserManagerService";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import { UserDTO } from "../../../models/users/UserDTO";
import OIMService from "../../../services/OIMService";
import { openOperationTabIfClosed } from "../../../utils/TabOpenUtils";

interface IncidentMenuProps {
  incident: OperationIncidentDTO;
  selectedIncidentId: string;
  clearIncidentCallback: () => void;
  refreshTableCallback: () => void;
  onOpen?: () => void;
  currentUser: UserDTO;
}

const IncidentMenu = ({
  incident,
  selectedIncidentId,
  clearIncidentCallback,
  refreshTableCallback,
  onOpen,
  currentUser,
}: IncidentMenuProps): JSX.Element => {
  const { t: translate } = useI18n();
  const globalContext = useGlobalContext();
  const { alertSubject } = globalContext.stateService;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [operatorsMenuAnchorEl, setOperatorsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [isOperatorsMenuOpen, setIsOperatorsMenuOpen] = useState(false);
  const [onlineOperators, setOnlineOperators] = useState<UserDTO[]>([]);
  const [isLoadingOnlineOperators, setIsLoadingOnlineOperators] =
    useState(false);
  const getIconButtonClass = useMemo(
    () => (isMenuOpen ? "active" : "inactive"),
    [isMenuOpen]
  );
  const isSelectedIncident = selectedIncidentId === incident.id;

  const closeAllMenus = () => {
    setAnchorEl(null);
    setOperatorsMenuAnchorEl(null);
    setIsOperatorsMenuOpen(false);
    setIsMenuOpen(false);
  };

  if (isMenuOpen && !isSelectedIncident) {
    closeAllMenus();
  }

  const openMenu = (event: React.MouseEvent<any>) => {
    event.stopPropagation();
    if (!isMenuOpen) {
      setAnchorEl(event.currentTarget);
      setIsMenuOpen(true);
    } else if (isSelectedIncident) {
      setAnchorEl(null);
      setIsMenuOpen(false);
    }
  };

  const closeMenu = (event?) => {
    event?.stopPropagation();
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const loadOnlineOperators = () => {
    setIsLoadingOnlineOperators(true);
    UserManagerService.loadOnlineOperators()
      .then((response) => {
        const onlineOperatorsArray = [];
        const currentUserIndexInResponse = response.users.findIndex(
          (operator) => operator?.userId === currentUser?.userId
        );
        if (currentUserIndexInResponse !== -1) {
          response.users.splice(currentUserIndexInResponse, 1);
        }
        if (currentUser) onlineOperatorsArray.push(currentUser);
        onlineOperatorsArray.push(...response.users);
        setOnlineOperators(onlineOperatorsArray);
        setIsLoadingOnlineOperators(false);
      })
      .catch(() => {
        setIsLoadingOnlineOperators(false);
        alertSubject.next({
          title: `${translate(
            "supervisor.assignIncident.failedFetchOnlineOperators"
          )}`,
        });
      });
  };

  const openOperatorsMenu = (event: React.MouseEvent<any>) => {
    event?.stopPropagation();
    setOperatorsMenuAnchorEl(event.currentTarget.parentElement);
    setIsOperatorsMenuOpen(true);
    loadOnlineOperators();
  };

  const closeOperatorsMenu = (event?) => {
    event?.stopPropagation();
    setOperatorsMenuAnchorEl(null);
    setIsOperatorsMenuOpen(false);
  };

  const assignIncidentToUser = (operator: UserDTO) => {
    OIMService.assignIncidentToUser(incident.id, operator?.userId)
      .then(refreshTableCallback)
      .catch((error) => {
        const errorResponse = JSON.parse(error.message);
        const failedToAssignIncidentMessage = translate(
          "supervisor.assignIncident.failedToAssign"
        );
        alertSubject.next({
          title: `${failedToAssignIncidentMessage} ${
            errorResponse.data.errorCode === 611
              ? `- ${translate("incidents.notAvailableForAssignment")}`
              : ""
          }`,
        });
      });
    if (currentUser && currentUser?.userId === operator?.userId) {
      openOperationTabIfClosed();
    }
    closeAllMenus();
  };

  return (
    <div data-cr="incident-menu" className="incident-menu-container-div">
      <MenuIcon
        aria-controls={isMenuOpen ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        onClick={
          (incident.assignedToUserId === null &&
            ((event) => {
              onOpen?.();
              openMenu(event);
            })) ||
          undefined
        }
        className={`incident-menu-icon ${
          incident.assignedToUserId !== null
            ? `incident-menu-button-disabled `
            : ""
        }
          ${
            isSelectedIncident
              ? `incident-menu-button-active ${getIconButtonClass}`
              : "incident-menu-button"
          }`}
      />
      <Menu
        open={isMenuOpen}
        onClose={closeMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          id="assign-incident-menu-item"
          onClick={openOperatorsMenu}
          className={isOperatorsMenuOpen ? "active" : "inactive"}
        >
          {translate("supervisor.assignIncident.assignIncidentToOperator")}
          <ArrowRightIcon />
        </MenuItem>
        <MenuItem
          onClick={(event?) => {
            event?.stopPropagation();
            closeMenu();
            clearIncidentCallback();
          }}
          id="clear-incident-menu-item"
        >
          {translate("supervisor.clearButton")}
        </MenuItem>
      </Menu>
      <Menu
        open={isOperatorsMenuOpen}
        onClose={closeOperatorsMenu}
        anchorEl={operatorsMenuAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: -5,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="operators-sub-menu"
      >
        {isLoadingOnlineOperators && (
          <MenuItem className="spinner-menu-item">
            <Spinner />
          </MenuItem>
        )}
        {!isLoadingOnlineOperators &&
          onlineOperators.length > 0 &&
          onlineOperators.map((operator, index) => (
            <MenuItem
              id={`operator-${operator.userId}`}
              key={operator.userId}
              onClick={(event) => {
                event?.stopPropagation();
                assignIncidentToUser(operator);
              }}
              className={
                index !== onlineOperators.length - 1 ? "border-bottom" : "none"
              }
            >
              {operator?.userId === currentUser?.userId
                ? translate("supervisor.assignIncident.currentUser")
                : `${operator.firstName} ${operator.lastName}`}
            </MenuItem>
          ))}
        {!isLoadingOnlineOperators && onlineOperators.length === 0 && (
          <MenuItem disabled>
            {translate("supervisor.assignIncident.noOperatorsFound")}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

IncidentMenu.defaultProps = {
  onOpen: undefined,
};

export default IncidentMenu;
