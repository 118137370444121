// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./activationsDetailsHeader.scss";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "dms-lib";

interface ActivationsDetailsHeaderProps {
  onClose: (event) => void;
}

const ActivationsDetailsHeader = ({
  onClose,
}: ActivationsDetailsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <div className="incident-activations__header">
      <h5>{translate("supervisor.activationDetails.title")}</h5>
      <Button
        variant="contained"
        color="primary"
        onClick={onClose}
        title={translate("close", { ns: "Shared" })}
        icon
      >
        <CloseIcon />
      </Button>
    </div>
  );
};

export default ActivationsDetailsHeader;
