import { BehaviorSubject } from "rxjs";
import { useGlobalContext } from "../contexts/GlobalContext";
import { PAGE_OPERATION_INCIDENT_INITIAL_STATE } from "../services/StateService";
// Models
import {
  OperationIncidentMap,
  PagedOperationIncidentAsMap,
} from "../models/supervisorDashboard/PagedOperationIncidentListDTO";
import { OperationIncidentDTO } from "../models/supervisorDashboard/OperationIncidentDTO";

const useDashboardDynamicFields = (
  operationIncidentMapSubject: BehaviorSubject<PagedOperationIncidentAsMap>
): {
  updateOperationIncidentListMap: (
    operationIncidentList: OperationIncidentDTO[],
    totalPages: number,
    totalRecords: number
  ) => void;
  updateOperationIncidentItem: (
    id: OperationIncidentDTO["id"],
    item: Partial<Omit<OperationIncidentDTO, "id">>
  ) => void;
  resetOperationIncidentListMap: () => void;
} => {
  const { stateService } = useGlobalContext();
  const { operationIncidentMap } = stateService || {
    operationIncidentMap: operationIncidentMapSubject,
  };

  const updateOperationIncidentListMap = (
    operationIncidentList: OperationIncidentDTO[],
    totalPages: number,
    totalRecords: number
  ) => {
    const operationIncidentToMap = operationIncidentList.reduce(
      (acc: OperationIncidentMap, operationListItem: OperationIncidentDTO) => {
        acc[operationListItem.id] = operationListItem;
        return acc;
      },
      {}
    );
    operationIncidentMap.next({
      operationIncidentMap: operationIncidentToMap,
      totalRecords,
      totalPages,
    });
  };

  const updateOperationIncidentItem = (
    id: OperationIncidentDTO["id"],
    item: Partial<Omit<OperationIncidentDTO, "id">>
  ) => {
    const currentIncident = operationIncidentMap.value.operationIncidentMap[id];

    // If there's no incident on the map then it is arriving a new one and not an activation.
    // In that case we should wait for a refresh or an automatic refresh
    // Later if we want to be reactive to the incidents from socket we can remove this condition
    if (!currentIncident) return;

    operationIncidentMap.next({
      ...operationIncidentMap.value,
      operationIncidentMap: {
        ...operationIncidentMap.value.operationIncidentMap,
        [id]: {
          ...operationIncidentMap.value.operationIncidentMap[id],
          ...item,
        },
      },
    });
  };

  const resetOperationIncidentListMap = () => {
    operationIncidentMap.next(PAGE_OPERATION_INCIDENT_INITIAL_STATE);
  };

  return {
    updateOperationIncidentListMap,
    updateOperationIncidentItem,
    resetOperationIncidentListMap,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useDashboardDynamicFields };
