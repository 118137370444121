import React from "react";
import { Button } from "dms-lib";
import { LocalizationNS, useI18n } from "compass-commons";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import CompassDialog from "../../commons/dialogCompass/DialogCompass";
import "./formCloseDialog.scss";

interface FormCloseDialogProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const FormCloseDialog = ({
  isOpen,
  onCancel,
  onConfirm,
}: FormCloseDialogProps): JSX.Element => {
  const { t: translate } = useI18n();

  return (
    <>
      {isOpen && (
        <CompassDialog
          onClose={() => {}}
          dialogState={isOpen}
          dialogContent={
            <div className="ondemand-dialog-content">
              <WarningRoundedIcon
                fontSize="large"
                className="ondemand-dialog-content-icon"
              />
              <span>{translate("ondemand.formCloseDialog")}</span>
            </div>
          }
          dialogActions={
            <div className="ondemand-dialog-actions">
              <Button
                dataCr="ondemand-close-cancel"
                variant="contained"
                color="primary"
                onClick={onCancel}
              >
                {translate("noConfirmDelete", { ns: LocalizationNS.SHARED })}
              </Button>
              <Button
                dataCr="ondemand-close-confirm"
                variant="outlined"
                color="primary"
                onClick={onConfirm}
              >
                {translate("confirmDelete", { ns: LocalizationNS.SHARED })}
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default FormCloseDialog;
