import React from "react";
// Styles
import Skeleton from "@mui/material/Skeleton/Skeleton";
import "./incidentActivationDetailsSkeleton.scss";

interface IncidentActivationDetailsSkeletonProps {
  numberOfRowSkeletons: number;
  numberOfColumns: number;
}
/**
 * IncidentActivationDetailsSkeleton component to display a skeleton
 * @param props
 * @constructor
 */
const IncidentActivationDetailsSkeleton = ({
  numberOfRowSkeletons,
  numberOfColumns,
}: IncidentActivationDetailsSkeletonProps): JSX.Element => {
  return (
    <div className="dashboard-incident-activations__details-skeleton-list">
      {Array.from({ length: numberOfRowSkeletons }, (_, rowIndex) => (
        <div
          key={`dashboard-incident-activations-skeleton-row-${rowIndex}`}
          className="dashboard-incident-activations__details-skeleton-row"
        >
          {Array.from({ length: numberOfColumns }, (__, colIndex) => (
            <Skeleton
              key={`dashboard-incident-activations-skeleton-col-${colIndex}-${rowIndex}`}
              variant="rectangular"
              className="dashboard-incident-activations__details-skeleton--rectangular"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default IncidentActivationDetailsSkeleton;
