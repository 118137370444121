// General
import React from "react";
// Material UI
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface CompassDialogProps {
  dialogState: boolean;
  onClose: (event?: any) => void;
  dialogContent?: JSX.Element;
  dialogActions?: JSX.Element;
  dialogPadding?: number | string;
  dataCr?: string;
}

const CompassDialog = ({
  dialogState,
  onClose,
  dialogContent,
  dialogActions,
  dialogPadding,
  dataCr,
}: CompassDialogProps): JSX.Element => {
  return (
    <Dialog data-cr={dataCr} open={dialogState} onClose={onClose}>
      {dialogContent ? (
        <DialogContent style={{ padding: dialogPadding }}>
          {dialogContent}
        </DialogContent>
      ) : null}
      {dialogActions ? (
        <DialogActions style={{ padding: dialogPadding }}>
          {dialogActions}
        </DialogActions>
      ) : null}
    </Dialog>
  );
};

CompassDialog.defaultProps = {
  dialogContent: undefined,
  dialogActions: undefined,
  dialogPadding: 0,
  dataCr: "delete-modal",
};

export default CompassDialog;
