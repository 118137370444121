import { HttpClient } from "compass-shared-services/src/index";
import { getUrl } from "compass-commons";
import { PubSubResponseDTO } from "../models/notification/PubSubResponseDTO";

const { NOTIFICATIONS_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  HUB_TOKEN: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/token`,
  USER_HUB_TOKEN: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/users/token`,
  ADD_USER_TO_GROUPS: `${NOTIFICATIONS_MANAGER_PATH}/azurepubsub/hubs/(hubName)/groups/user`,
};

export default class NotificationsManagerService {
  static async getUserHubToken(
    hubName: string
  ): Promise<PubSubResponseDTO | void> {
    const uri = {
      hubName,
    };
    const urlPath = getUrl(URL_PATHS.USER_HUB_TOKEN, uri);

    return httpClient
      .get<PubSubResponseDTO>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static async addUserToGroups(hubName: string): Promise<string | void> {
    const uri = {
      hubName,
    };
    const urlPath = getUrl(URL_PATHS.ADD_USER_TO_GROUPS, uri);

    return httpClient
      .post<string>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}
