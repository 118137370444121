/* eslint-disable */
export const COMMENTS_MAXIMUM_CHARACTERS = 256;

// URL PARAMS
export const AUTOMATIC_UPDATE_PARAM = "automaticUpdate";

// DASHBOARD
// Equivelent to 10 seconds
export const INCIDENTS_AUTOMATIC_UPDATE_TIMER = 10000;

// FEATURES KEYS
export const AUTOMATIC_UPDATE_FEATURE_FLAG = "d_dashboard-automatic-update";
export const GROUPING_BY_ACTIVATION_FEATURE_FLAG =
  "d_incidentqueue_groupingByActivation";
export const SHOW_GENERIC_ERROR_MESSAGES_FEATURE_FLAG =
  "b_showGenericErrorMessages";
  export const ON_DEMAND_INCIDENT_FEATURE_FLAG =
  "d_ondemand";
