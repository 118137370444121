import ReconnectingWebSocket from "reconnecting-websocket";
import NotificationsManagerService from "./NotificationsManagerService";

class ListenerService {
  private readonly sockets: Map<string, WebSocket | ReconnectingWebSocket>;

  constructor() {
    this.sockets = new Map<string, WebSocket>();
  }

  /**
   * Method that opens a websocket to a given hubname through the notification manager service.
   * @param hubName - Name of the hub to connect to
   * @param func - Function to be executed when a message is received
   * @param identifier -  Identifier suffix that can be added to allow multiple sockets for the same hub
   */
  listen(hubName: string, func: (msg: any) => void, identifier = ""): void {
    const id = hubName + identifier;
    if (!this.sockets.get(id)) {
      NotificationsManagerService.getUserHubToken(hubName)
        .then((res) => {
          if (res) {
            const socket = new WebSocket(res.Url);
            socket.onmessage = (messageEvent) => {
              func(messageEvent.data);
            };
            this.sockets.set(id, socket);
            socket.onopen = () => {
              NotificationsManagerService.addUserToGroups(hubName).catch(
                (error) => {
                  throw error;
                }
              );
            };
          }
        })
        .catch();
    }
  }

  /**
   * Method that closes a given websocket identified by the hubName and an optional identifier suffix
   * @param hubName - Name of the hub that to which the socket is open
   * @param identifier - Identifier suffix that can be added to allow multiple sockets for the same hub
   */
  stopListening(hubName: string, identifier = ""): void {
    const id = hubName + identifier;
    const socket = this.sockets.get(id);
    if (socket) {
      this.sockets.delete(id);
      socket.close();
    } else {
      // eslint-disable-next-line
      console.error("Could not find open websocket.");
    }
  }
}

export default ListenerService;
