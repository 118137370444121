/**
 * ROUTER DASHBOARD HELPERS
 */
// The root path for this configuration microFrontend
/* eslint-disable import/prefer-default-export */

import { isUnity } from "compass-commons";

function getMiddlePath(url: string, first: string, second: string) {
  const regex = new RegExp(`/${first}(.*?)${second}`);
  return regex.exec(url)?.[1] || "/";
}

const COMPASS_ROOTPATH =
  appConfig.COMPASS_DASHBOARDS_PATH || "/ui/dev/live/dashboards";
const COMPASS_OPERATION_PATH =
  appConfig.COMPASS_OPERATION_PATH || "/ui/dev/live/operation";

const url = !isStandalone ? window.location.href : "/monitor/dashboards";
const middlePath = getMiddlePath(url, "monitor", "dashboards");

export const ROOTPATH = isUnity
  ? `/monitor${middlePath}dashboards`
  : COMPASS_ROOTPATH;

export const OPERATION_PATH = isUnity
  ? `/monitor${middlePath}operation`
  : COMPASS_OPERATION_PATH;

export const ONDEMAND_INCIDENT_ROUTE = "ondemand-incident";
