import React from "react";
// Project modules
import "./activationMenu.scss";

interface ActivationMenuProps {
  incidentAdditionalActivationsCount: number;
}

const ActivationMenu = ({
  incidentAdditionalActivationsCount,
}: ActivationMenuProps): JSX.Element => {
  return (
    <div data-cr="activation-menu" className="activation-menu-container-div">
      <span>{incidentAdditionalActivationsCount + 1}</span>
    </div>
  );
};

export default ActivationMenu;
