// General
import React, { useCallback, useState } from "react";
import { BehaviorSubject } from "rxjs";
import { useFeatureFlag, useI18n } from "compass-commons";
// Styles
import "./tableActionsHeader.scss";
import { Button, Checkbox } from "dms-lib";
import AddRounded from "@mui/icons-material/AddRounded";
import FormControlLabel from "@mui/material/FormControlLabel";
// Components
import { useNavigate } from "react-router-dom";
import SupervisorBadge from "../../../commons/badges/supervisor/SupervisorBadge";
import CompassDialog from "../../../commons/dialogCompass/DialogCompass";
import ODBehaviorDialogContent from "../../../onDemandIncident/dialog/ODBehaviorDialogContent";
import ODBehaviorDialogActions from "../../../onDemandIncident/dialog/ODBehaviorDialogActions";
// Models
import { OperationIncidentInfo } from "../../../../models/notification/OperationIncidentInfo";
import { ClearIncidentRequestDTO } from "../../../../models/supervisorDashboard/ClearIncidentRequestDTO";
import { AutomaticRefreshFeature } from "../../../../models/dashboardAutomaticRefresh/AutomaticRefreshFeature";
import { ONDEMAND_INCIDENT_ROUTE } from "../../../../router/Routes";
import { ON_DEMAND_INCIDENT_FEATURE_FLAG } from "../../../../utils/Constants";
import BehaviorService from "../../../../services/BehaviorService";

interface TableActionsHeaderProps {
  title: string;
  hasTableData: boolean;
  operationIncidentInfoSubject?: BehaviorSubject<OperationIncidentInfo>;
  resultTableCallback?: (result: ClearIncidentRequestDTO) => void; // if specific incident clicked, then pass its id,if not pass empty string
  onClickCallBack?: () => void;
  automaticRefreshFeature?: AutomaticRefreshFeature;
  className?: string;
}

const TableActionsHeader = (props: TableActionsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const navigate = useNavigate();
  const {
    title,
    hasTableData,
    operationIncidentInfoSubject,
    resultTableCallback,
    onClickCallBack,
    automaticRefreshFeature,
    className,
  } = props;

  const [openODBehaviorModal, setOpenODBehaviorModal] = useState(false);
  // eslint-disable-next-line
  const toggleModal = useCallback((forceState?: boolean) => {
    setOpenODBehaviorModal((prevState: boolean) => forceState ?? !prevState);
  }, []);
  const oDBehaviorDialog = () => {
    toggleModal(false);
  };
  const onDemandClicked = () => {
    const getHasBehavior = async () => {
      try {
        const hasBehavior = await BehaviorService.getCustomerHasOnDemandRules();
        if (hasBehavior) {
          navigate(ONDEMAND_INCIDENT_ROUTE, {
            state: { isButtonClicked: true },
          });
        } else {
          toggleModal(true);
        }
      } catch (error) {
        console.error("Error when trying to get behaviors", error);
        toggleModal(true);
      }
    };

    getHasBehavior();
  };
  const { enabled: onDemandFeatureEnabled } = useFeatureFlag(
    appConfig,
    ON_DEMAND_INCIDENT_FEATURE_FLAG
  );

  return (
    <div
      className={`dashboards__table-actions-header${
        className ? ` ${className}` : ""
      }`}
    >
      <span>{title}</span>
      <div className="dashboards__table-actions">
        {automaticRefreshFeature?.enabled && (
          <FormControlLabel
            data-cr="incidents-automatic-update"
            value="start"
            className="dashboards__table-automatic-form-control"
            control={
              <Checkbox
                checked={automaticRefreshFeature?.value}
                CheckboxClassName="dashboards__table-automatic"
                onChange={(_event, checked) => {
                  automaticRefreshFeature?.handleAutomaticRefresh(checked);
                }}
              />
            }
            label={translate("supervisor.automaticUpdateButton")}
            labelPlacement="start"
          />
        )}
        <SupervisorBadge
          operationIncidentInfoSubject={operationIncidentInfoSubject}
          onClickCallBack={onClickCallBack}
        />
        {onDemandFeatureEnabled && (
          <div style={{ display: "flex" }}>
            <Button
              dataCr="dashboard-new-incident-button"
              color="primary"
              variant="contained"
              className="dashboard_new-incident"
              onClick={onDemandClicked}
            >
              <AddRounded />
              <span>{translate("ondemand.newIncidentButton")}</span>
            </Button>
          </div>
        )}
        <CompassDialog
          onClose={oDBehaviorDialog}
          dialogState={openODBehaviorModal}
          dialogContent={<ODBehaviorDialogContent />}
          dialogActions={
            <ODBehaviorDialogActions onConfirm={oDBehaviorDialog} />
          }
        />
        {hasTableData && (
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              color="primary"
              className="msi-btn"
              onClick={() =>
                resultTableCallback({ timeStamp: new Date().toString() })
              }
              dataCr="dashboard-clear-all-button"
            >
              <span>{translate("supervisor.clearAllButton")}</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

TableActionsHeader.defaultProps = {
  operationIncidentInfoSubject: null,
  onClickCallBack: () => null,
  resultTableCallback: () => null,
  automaticRefreshFeature: undefined,
  className: undefined,
};

export default TableActionsHeader;
