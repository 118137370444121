import React, { useEffect } from "react";
import { Control, Controller } from "react-hook-form";
import { AutoComplete } from "dms-lib";
import { useApi, useI18n } from "compass-commons";
import { SelectOptionGenericId } from "../../../models/onDemandIncident/selectOption";
import { OnDemandIncidentFormDTO } from "../../../models/onDemandIncident/onDemandIncidentForm";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import FloorplanService from "../../../services/FloorplanService";

interface FloorPlanSelectorProps {
  control: Control<OnDemandIncidentFormDTO>;
  selectedSite: string;
}

const FloorPlanSelector = ({
  control,
  selectedSite,
}: FloorPlanSelectorProps): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    stateService: { alertSubject },
  } = useGlobalContext();

  const { data, loading, error } = useApi(
    FloorplanService.getFloorPlansBySiteId,
    selectedSite,
    true
  );

  useEffect(() => {
    if (error) {
      alertSubject.next({
        title: translate("ondemand.failToLoadFloorPlan"),
      });
    }
  }, [error]);

  return (
    <Controller
      name="floorplan"
      control={control}
      render={({ field: { onChange, value, ...field } }) => {
        return (
          <div>
            <div className="ondemand-form-section-label">
              {translate("ondemand.floorplanInputLabel")}
            </div>
            <AutoComplete<SelectOptionGenericId>
              {...field}
              disabled={!selectedSite}
              value={value as SelectOptionGenericId}
              placeholder={translate("ondemand.floorplanInputPlaceholder")}
              options={data || []}
              loading={loading}
              dataCr="incident-selector-floorplan"
              getOptionLabel={(option) => option?.name || ""}
              onChangeCallback={(floorplan: SelectOptionGenericId) => {
                onChange(floorplan);
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default FloorPlanSelector;
