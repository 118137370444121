// General
import React, { useEffect, useMemo, useState } from "react";
import { format, compareAsc } from "date-fns";
// Hooks
import { useFeatureFlag, useI18n } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { IncidentIcon } from "dms-lib";
import Badge from "@mui/material/Badge";
import { useActivations } from "../../../hooks/useActivations";
// Components
import TableView from "../../commons/tableView/TableView";
import ActivationMenu from "../activationMenu";
import IncidentMenu from "../incidentMenu";
// Styles
import "./resultTable.scss";
// Store
import { useGlobalContext } from "../../../contexts/GlobalContext";
// Models
import { OperationIncidentDTO } from "../../../models/supervisorDashboard/OperationIncidentDTO";
import { ClearIncidentRequestDTO } from "../../../models/supervisorDashboard/ClearIncidentRequestDTO";
import { OperationIncidentInfo } from "../../../models/notification/OperationIncidentInfo";
import { AutomaticRefreshFeature } from "../../../models/dashboardAutomaticRefresh/AutomaticRefreshFeature";
import { EActivationsState } from "../../../models/supervisorDashboard/IncidentAdditionalActivation";
// Utils
import { GROUPING_BY_ACTIVATION_FEATURE_FLAG } from "../../../utils/Constants";
import useCurrentUser from "../../../hooks/useCurrentUser";

interface ResultTableProps {
  resultTableCallback?: (result: ClearIncidentRequestDTO) => void; // if specific incident clicked, then pass its id,if not pass empty string
  fetchData?: ({ pageIndex, pageSize }, sortBy?, sortDirection?) => void;
  loading?: boolean;
  operationIncidentInfoSubject?: BehaviorSubject<OperationIncidentInfo>;
  automaticRefreshFeature?: AutomaticRefreshFeature;
  badgeClickCallback?: () => void;
  manualAssignCallback: () => void;
  openedIncidentActivationsCallback: (arg: string) => void;
}

const ResultTable = (props: ResultTableProps): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    resultTableCallback,
    fetchData,
    loading,
    badgeClickCallback,
    manualAssignCallback,
    operationIncidentInfoSubject,
    automaticRefreshFeature,
    openedIncidentActivationsCallback,
  } = props;

  // FEATURE FLAGS
  const { enabled: groupingByActivation } = useFeatureFlag(
    appConfig,
    GROUPING_BY_ACTIVATION_FEATURE_FLAG
  );

  const { getActivationsDetails } = useActivations();
  const [incidentList, setIncidentList] = useState<OperationIncidentDTO[]>();
  const [pageCount, setPageCount] = useState<number>();
  const [totalRecords, setTotalRecords] = useState<number>();

  const { stateService } = useGlobalContext();
  const { operationIncidentMap, latestTriggeredTimeByResult, alertSubject } =
    stateService;

  const [selectedRow, setSelectedRow] = useState<OperationIncidentDTO>(null);
  const [isActivationsDetailsOpen, setIsActivationsDetailsOpen] =
    useState<boolean>(false);
  const [clearExecutionTime, setClearExecutionTime] = useState<number>(null);
  const currentUser = useCurrentUser();

  const fetchActivationsDetailsFirstPage = (row) => {
    getActivationsDetails(
      row.id,
      row.siteId,
      row.siteName,
      row.additionalActivationsCount,
      EActivationsState.FIRST_PAGE
    );
  };

  const onCloseActivationsDetails = () => {
    setIsActivationsDetailsOpen(false);
    openedIncidentActivationsCallback(null);
  };

  useEffect(() => {
    if (isActivationsDetailsOpen && selectedRow?.disabled) {
      onCloseActivationsDetails();
    }
  }, [selectedRow, isActivationsDetailsOpen]);

  /**
   * There's multiple conditions to fetch new data or not.
   * 1 - Clicking the same row and panel is already opened there's nothing to be done
   * 2 - if feature flag is disabled
   * 2.1 - Clicking the row or icon menu only selects the row. Don't need to open details or fetch activations
   * 3 - if feature enabled
   * 3.2 - Clicking the menu icon inside the row but panel is closed, then there's nothing to be done as well, besides selecting the clicked row
   * 3.3 - Clicking the menu icon inside the row but panel is opened, then we select the new row, and fetch new row activations
   * @param row
   * @param keepClosed
   */
  const updateSelectedRow = (row: OperationIncidentDTO, keepClosed = false) => {
    if (row?.disabled) {
      onCloseActivationsDetails();
      alertSubject.next({
        title: translate("supervisor.clearedIncidentWarning", {
          incident: row?.incidentDescription,
        }),
        severity: "warning",
      });
      return;
    }

    if (selectedRow?.id === row.id && isActivationsDetailsOpen) return;
    setSelectedRow(row);
    if (!groupingByActivation || (keepClosed && !isActivationsDetailsOpen))
      return;
    setIsActivationsDetailsOpen(true);
    openedIncidentActivationsCallback(row.id);
    fetchActivationsDetailsFirstPage(row);
  };

  const safelyFormatDate = (dateString: string) => {
    const date = new Date(dateString);

    return date ? format(date, "LLL d HH:mm:ss") : null;
  };

  const hasTableData = () => {
    return !(incidentList === undefined || incidentList.length === 0);
  };

  const getClearElement = (row: OperationIncidentDTO) => {
    return (
      <>
        {row?.id && !row?.disabled ? (
          <IncidentMenu
            incident={row}
            selectedIncidentId={selectedRow?.id || ""}
            clearIncidentCallback={() => {
              onCloseActivationsDetails();
              resultTableCallback({
                incidentId: row.incidentId,
                executionId: row.operatorGuideExecutionId,
              });
            }}
            refreshTableCallback={manualAssignCallback}
            onOpen={() => updateSelectedRow(row, true)}
            currentUser={currentUser}
          />
        ) : null}
      </>
    );
  };

  const getAcitvationElement = (row: OperationIncidentDTO) => {
    return (
      <>
        {row?.id ? (
          <ActivationMenu
            incidentAdditionalActivationsCount={row.additionalActivationsCount}
          />
        ) : null}
      </>
    );
  };

  const isNewIncident = (incidentTimestamp: string) => {
    return clearExecutionTime
      ? compareAsc(new Date(incidentTimestamp), new Date(clearExecutionTime)) >
          0
      : false;
  };

  const getIncidentNameAndIcon = (row: OperationIncidentDTO) => {
    return (
      <div className="supervisor-incident-name-and-icon">
        {row?.id
          ? row.incidentType.iconPath && (
              <div
                className="supervisor-badge-wrapper"
                data-cr="supervisor-badge-wrapper"
              >
                {isNewIncident(row.incidentTimestamp) ? (
                  <Badge className="badge-icon" color="primary" variant="dot">
                    <IncidentIcon
                      src={row.incidentType.iconPath}
                      priority={String(row.priority)}
                      size="medium"
                    />
                  </Badge>
                ) : (
                  <IncidentIcon
                    src={row.incidentType.iconPath}
                    priority={String(row.priority)}
                    size="medium"
                  />
                )}
              </div>
            )
          : null}
        <span
          style={{
            fontWeight: "bold",
            marginTop: "0px",
            marginBottom: "0px",
            textAlign: "start",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {row.incidentDescription}
        </span>
      </div>
    );
  };

  /**
   * This is used for table columns and
   * its configuration and style.
   */
  const tableHeaders = React.useMemo(
    () => ({
      Incident: `${translate("supervisor.incidentTable.incident")}:`,
      Activation: `${translate("supervisor.incidentTable.activation")}:`,
      Site: `${translate("supervisor.incidentTable.site")}:`,
      Resource: `${translate("supervisor.incidentTable.resource")}:`,
      TriggeredTime: `${translate("supervisor.incidentTable.triggeredTime")}:`,
      OGTemplate: `${translate(
        "supervisor.incidentTable.operatorGuideTemplate"
      )}:`,
      Operator: `${translate("supervisor.incidentTable.operator")}:`,
      Clear: "",
    }),
    []
  );

  const generateColumnsData = useMemo(
    () => ({
      id: {
        fieldName: "ID",
        size: 0,
        headerName: "ID",
        accessor: (element: OperationIncidentDTO) => element.id,
        hiddenColumn: true,
      },
      incident: {
        fieldName: "incident",
        size: groupingByActivation ? 20 : 30,
        headerName: tableHeaders.Incident,
        accessor: (element: OperationIncidentDTO) =>
          getIncidentNameAndIcon(element),
      },
      activation: {
        fieldName: "additionalActivationsCount",
        size: 10,
        headerName: tableHeaders.Activation,
        accessor: (element: OperationIncidentDTO) =>
          getAcitvationElement(element),
        hiddenColumn: !groupingByActivation,
      },
      site: {
        fieldName: "site",
        size: 10,
        headerName: tableHeaders.Site,
        accessor: (element: OperationIncidentDTO) => element.siteName,
      },
      resource: {
        fieldName: "resource",
        size: 15,
        headerName: tableHeaders.Resource,
        accessor: (element: OperationIncidentDTO) =>
          element.resourceMappingName,
      },
      triggeredTime: {
        fieldName: "triggeredTime",
        size: 10,
        headerName: tableHeaders.TriggeredTime,
        accessor: (element: OperationIncidentDTO) =>
          (element.incidentTimestamp &&
            safelyFormatDate(element.incidentTimestamp)) ||
          "",
      },
      ogTemplate: {
        fieldName: "ogTemplate",
        size: 15,
        headerName: tableHeaders.OGTemplate,
        accessor: (element: OperationIncidentDTO) =>
          element.operatorGuideTemplateName,
      },
      operator: {
        fieldName: "operator",
        size: 15,
        headerName: tableHeaders.Operator,
        accessor: (element: OperationIncidentDTO) =>
          element.assignedToUserFullName,
      },
      clear: {
        fieldName: "clear",
        size: 5,
        headerName: tableHeaders.Clear,
        accessor: (element: OperationIncidentDTO) => getClearElement(element),
      },
    }),
    [
      selectedRow?.id,
      groupingByActivation,
      isActivationsDetailsOpen,
      clearExecutionTime,
    ]
  );

  const onRowClick = (row: OperationIncidentDTO) => {
    updateSelectedRow(row);
  };

  useEffect(() => {
    const sub = latestTriggeredTimeByResult.subscribe(setClearExecutionTime);
    return () => {
      sub.unsubscribe();
    };
  }, [latestTriggeredTimeByResult]);

  useEffect(() => {
    const sub = operationIncidentMap.subscribe((value) => {
      setIncidentList(Object.values(value.operationIncidentMap));
      setTotalRecords(value.totalRecords);
      setPageCount(value.totalPages);
      setSelectedRow(
        (prevValue) => value.operationIncidentMap[prevValue?.id] || prevValue
      );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [operationIncidentMap]);

  return (
    <TableView
      onRowClickCallback={onRowClick}
      data={incidentList}
      columns={generateColumnsData}
      totalPageCount={pageCount}
      apiCallFunc={fetchData}
      totalDataCount={totalRecords}
      loading={loading}
      selectedRow={selectedRow}
      showTotal
      totalText={translate("supervisor.incidentTable.activeIncidents")}
      hasData={hasTableData()}
      operationIncidentInfoSubject={operationIncidentInfoSubject}
      resultTableCallback={(result: ClearIncidentRequestDTO) => {
        onCloseActivationsDetails();
        resultTableCallback(result);
      }}
      badgeClickCallback={badgeClickCallback}
      automaticRefreshFeature={automaticRefreshFeature}
      showActivationsDetailsPanel={isActivationsDetailsOpen}
      onCloseSidePanel={onCloseActivationsDetails}
    />
  );
};

ResultTable.defaultProps = {
  resultTableCallback: () => null,
  fetchData: () => null,
  loading: false,
  operationIncidentInfoSubject: null,
  badgeClickCallback: () => null,
  automaticRefreshFeature: undefined,
};

export default ResultTable;
