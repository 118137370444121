import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { convertToBoolean } from "../utils/fp";

const useSearchParamsState = (
  searchParamName: string,
  defaultValue: string
): readonly [
  searchParamsState: string,
  setSearchParamsState: (newState: string) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const acquiredSearchParam = searchParams.get(searchParamName);
  const searchParamsState = acquiredSearchParam ?? defaultValue;

  const setSearchParamsState = (newState: string) => {
    const next = {
      ...[...searchParams.entries()].reduce(
        (o, [key, value]) => ({ ...o, [key]: value }),
        {}
      ),
      [searchParamName]: newState,
    };
    setSearchParams(next);
  };
  return [searchParamsState, setSearchParamsState];
};

export const useBooleanSearchParamState = (
  searchParamName: string,
  defaultValue: string
): readonly [
  searchParamsState: boolean,
  setSearchParamsState: (newState: boolean) => void
] => {
  const [searchParams, setSearchParams] = useSearchParamsState(
    searchParamName,
    defaultValue
  );
  const [booleanSearchParam, setBooleanSearchParam] = useState<boolean>(
    convertToBoolean(searchParams)
  );

  const setBooleanSearchParamState = (booleanState: boolean) => {
    setSearchParams(booleanState.toString());
    setBooleanSearchParam(booleanState);
  };
  return [booleanSearchParam, setBooleanSearchParamState];
};

export default useSearchParamsState;
